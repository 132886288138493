/* -- Particle controller -- */
/* -------------------------------------------------------------------------------------- */
/*                                                                                        */
/*  For now only used to display a happy burst when starting a new session                */
/*    But it might be good for collecting points and stuff if I ever add that             */
/*                                                                                        */
/* -------------------------------------------------------------------------------------- */

let particleController = function() {

  let canvas = document.getElementById('particlecanvas');
  let cw = canvas.width = window.innerWidth;
  let ch = canvas.height = window.innerHeight;
  let ctx = canvas.getContext('2d');
  let colorArr = ['#F4D03F','#F1C40F','#F5B041','#F1C40F','#F39C12'];
  let particleList = {};
  let particleCount = 40;
  let maxAge = 50;
  let animating = false;

  function resizeCanvas() {
    cw = canvas.width = window.innerWidth;
    ch = canvas.height = window.innerHeight;
  }
  resizeCanvas();

  window.addEventListener('resize', resizeCanvas, false);

  function Circle(index, x, y) {

    this.id = index;
    this.x = x;
    this.y = y;
    this.gravity = 0.4;
    this.opacity = 1;

    this.radius = (Math.random() * 2) + 4;
    this.xSpeed = (Math.random() * 10) - 5;
    this.ySpeed = (Math.random() * 10) - 9;
    this.color = colorArr[Math.floor(Math.random()*colorArr.length)];
    this.age = ((.6 * maxAge) * Math.random()) + (.4 * maxAge);
    this.fadeSpeed = this.age / 1000;

    // Prevent square burst
    if (this.xSpeed < -3 && this.ySpeed < -7 || this.xSpeed > 3 && this.ySpeed < -7 || this.xSpeed < -3 && this.ySpeed > -1 || this.xSpeed > 3 && this.ySpeed > -1) {
      this.xSpeed *= 0.8 - (Math.random() * 0.5);
      this.ySpeed *= 0.8 - (Math.random() * 0.5);
    }

    this.draw = () => {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = this.color;
      ctx.fill();
    }

    this.update = () => {
      this.age--;
      if (this.age < (.4 * maxAge)) { this.opacity -= this.fadeSpeed; }
      ctx.globalAlpha = this.opacity;
      if (this.opacity <= 0 || this.x + this.radius > cw || this.x - this.radius < 0 || this.y + this.radius > ch || this.y - this.radius < 0) {
        delete particleList[this.id];
      }
      this.x += this.xSpeed;
      this.y += this.ySpeed;
      this.ySpeed += this.gravity;
      this.draw();
    }
  }

  function animateParticles() {
    animating = true;
    ctx.clearRect(0, 0, cw, ch);
    if (Object.keys(particleList).length > 0) {
      Object.keys(particleList).forEach(particle => particleList[particle].update());
      requestAnimationFrame(animateParticles);
    } else {
      animating = false;
    }
  }

  let burst = _e => {
    if (_e.button === 0) {
      particleList = {};
      for (let i = 0; i < particleCount; i++) {
        particleList[i] = new Circle(i, _e.clientX, _e.clientY);
        // particleList[i] = new Circle(i, Math.random() * window.innerWidth, _e.clientY);
      }
    }
    if (animating != true) { animateParticles(); }
  };

  return {
    burst: burst,
  }

};

exports.particleController = particleController;