/* -- Navigation controller -- */
/* -------------------------------------------------------------------------------------- */
/*                                                                                        */
/*  Handle page navigation and menu interaction                                           */
/*                                                                                        */
/* -------------------------------------------------------------------------------------- */

const Page = require("../classes/Page.js");

let navController = function() {

  let elMenu = document.querySelector('#header nav ul');

  // Collect pages
  document.querySelectorAll('#maincontent .page').forEach(elPage => Page.list.push(new Page(elPage.id)));

  // Open page
  let openPage = function(_id) {
    Page.openPage(_id);
  }

  // Close the menu
  function closeMenu() {
    elMenu.classList.remove('open');
  }

  // Click handler
  document.addEventListener('click', function(_e) {
    if (_e.target.closest('#menutoggle')) {

      // Open / close menu
      if (!elMenu.classList.contains('open')) {
        elMenu.classList.add('open');
      } else {
        closeMenu();
      }

    } else if (_e.target.closest('#header nav li')) {

      // Open page
      openPage(_e.target.closest('#header nav li').dataset.target);
      closeMenu();

    } else {
      return false;
    }
  }, false);

  return {
    openPage:  openPage,
    closeMenu: closeMenu,
  }

}

exports.navController = navController;