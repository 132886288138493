module.exports = class Page {

  static list = [];
  static activePage = false;

  static openPage(_id) {

    if (_id != this.activePage) {

      this.list.find(page => {
        if (page.id == this.activePage) { page.closePage(); }
      });

      this.list.find(page => {
        if (page.id == _id) { page.openPage(); }
      });

    }
  }

  constructor(_id) {

    this.id = _id;
    this.el = document.getElementById(_id);
    this.active = false;

  }

  openPage() {

    this.active = true;
    Page.activePage = this.id;

    this.el.style.display = 'block';

  }

  closePage() {

    this.active = false;
    this.el.style.display = 'none';

    if (this.id == 'trainingsession') {
      qptApp.exercise.stopSession();
    }

  }

}