/* -- Index controller -- */
/* -------------------------------------------------------------------------------------- */
/*                                                                                        */
/*  Main app code                                                                         */
/*                                                                                        */
/* -------------------------------------------------------------------------------------- */

let indexController = function() {

  let wwcData        = {},
      allCodes       = ['BB', 'BE', 'BL', 'CK', 'FK', 'JW', 'MdJ', 'MvdB', 'RG', 'RvV', 'ST1', 'ST2', 'ST3', 'SV', 'TvdK'],
      availableCodes = JSON.parse(JSON.stringify(allCodes)),
      selectedPerson = false,
      guestCount     = 0,
      pling          = new Audio('audio/pling.wav'),
      elSettingsBtn  = document.getElementById('settingsbutton'),
      elSettings     = document.getElementById('settings'),
      elAudioToggle  = document.querySelector('#audiotoggle input')
      elSortlist     = document.getElementById('sortpeople'),
      elWelcome      = document.getElementById('welcome'),
      elOverview     = document.getElementById('overview'),
      elOrders       = document.getElementById('orders'),
      elReset        = document.getElementById('reset'),
      elSelector     = document.getElementById('selector'),
      elBack         = document.getElementById('back'),
      elPeople       = document.getElementById('people'),
      elDrinks       = document.getElementById('drinks');

  function addPerson(_id, _orderList) {
    output = '';
    if (_orderList != true || _orderList == true && wwcData.people[_id].ordered != true) {
      output = `<div class="button" data-id="${_id}" data-code="${wwcData.people[_id].code}">${wwcData.people[_id].name}</div>`;
    }
    return output;
  }

  function getPeople(_orderList = false) {
    let output = '';
    let refreshedOrder = [];
    let clientData = Cookies.get('wwc-custom-order');
    if (clientData) {

      // Load in custom order
      let customOrder = JSON.parse(clientData);
      customOrder.forEach(_id => {
        if (wwcData.people[_id]) {
          output += addPerson(_id, _orderList);
          refreshedOrder.push(_id);
        } else {
          log(`Id ${_id} not found, removing from customOrder`);
        }
      });

      // Add any new id's missing from customOrder
      Object.keys(wwcData.people).forEach(_key => {
        if (!refreshedOrder.includes(_key)) {
          log(`New id ${_key} found, adding to orderList and customOrder`);
          output += addPerson(_key, _orderList);
          refreshedOrder.push(_key);
        }
      });

    } else {
      // Load in default order
      Object.keys(wwcData.people).forEach(_key => output += addPerson(_key, _orderList));
    }
    if (refreshedOrder.length > 0) {
      // Refreshing custom order cookie
      Cookies.set('wwc-custom-order', JSON.stringify(refreshedOrder), { expires: 365 });
    }
    if (_orderList == true) {
      output += `<div class="button other" data-id="?" data-code="">Gast</div>`;
    }
    return output;
  }

  function populateLists() {

    // People
    elPeople.innerHTML = getPeople(true);

    // Drinks
    let listDrinks = '';
    Object.keys(wwcData.drinks).forEach(_key => {
      listDrinks += `<div class="button" data-id="${_key}">${wwcData.drinks[_key].name}</div>`;
    });
    listDrinks += `<div class="button other" data-id="?">Anders...</div>`;
    elDrinks.innerHTML = listDrinks;

  }

  let init = _wwcData => {

    wwcData = _wwcData;

    pling.volume = 0.2;
    let audioCookie = Cookies.get('wwc-audio');
    if (audioCookie) {
      if (audioCookie == 'true') {
        wwcApp.settings.audio = true;
      } else {
        wwcApp.settings.audio = false;
        elAudioToggle.checked = false;
        Cookies.set('wwc-audio', false, { expires: 365 });
      }
    }

    Object.keys(wwcData.people).forEach(_key => wwcData.people[_key].note = '');

    wwcApp.nav.openPage('index');

  }

  function emptyList() {
    // Show welcome, hide reset button
    elWelcome.classList.remove('hidden');
    elReset.classList.add('hidden');
  }

  function getSortedKeys(obj) {
    var keys = Object.keys(obj);
    return keys.sort(function(a,b) { return obj[b] - obj[a] });
  }

  function refreshOverview() {

    let allDrinks = [];
    Object.keys(wwcData.people).forEach(_key => {
      if (wwcData.people[_key].ordered == true && wwcData.people[_key].drink != '') {
        if (allDrinks[wwcData.people[_key].drink]) {
          allDrinks[wwcData.people[_key].drink]++;
        } else {
          allDrinks[wwcData.people[_key].drink] = 1;
        }
      }
    });

    let sumTexts = [];
    let orderedSum = getSortedKeys(allDrinks);
    orderedSum.forEach(_key => {
      sumTexts.push(`${allDrinks[_key]} ${_key}`);
    });

    elOverview.innerHTML = sumTexts.join(', ');
  }

  function getDrinkName(_person, _drink) {
    let output = '<span class="other">Iets anders</span>';
    wwcData.people[_person].drink = 'Anders';
    if (_drink != '?') {
      output = wwcData.drinks[_drink].name;
      wwcData.people[_person].drink = output;
    }
    return output;
  }

  function buildOrderElement(_person, _drink) {

    let order = document.createElement('div');
    order.classList.add('order');
    order.dataset.person = _person;

    // Order data
    let orderData = document.createElement('div');
    orderData.classList.add('orderdata');

      // Name
      let orderWho = document.createElement('div');
      orderWho.classList.add('who');
      orderWho.innerHTML = wwcData.people[_person].name;
      orderData.append(orderWho);

      // Drink
      let orderWhat = document.createElement('div');
      orderWhat.classList.add('what');
      let drinkName = getDrinkName(_person, _drink);
      orderWhat.innerHTML = drinkName;
      orderData.append(orderWhat);

      // Plate code
      let orderCode = document.createElement('div');
      orderCode.classList.add('code');
      let displayCode = wwcData.people[_person].code;
      if (displayCode == '') {
        orderCode.classList.add('unknown');
        displayCode = '?';
      }
      orderCode.innerHTML = displayCode;
      orderData.append(orderCode);

      // Menu button
      let orderOptions = document.createElement('div');
      orderOptions.classList.add('options');
      orderOptions.innerHTML = '<img src="img/options.png" />';
      orderData.append(orderOptions);

    order.append(orderData);

    // Order menu
    let orderMenu = document.createElement('div');
    orderMenu.classList.add('menu');

      // Select plate code
      if (wwcData.people[_person].code == '') {
        let orderButtonCode = document.createElement('div');
        orderButtonCode.classList.add('codebutton');
        orderButtonCode.innerHTML = '> Dienblad code kiezen';
        orderMenu.append(orderButtonCode);
      }

      // Edit drink choice
      let orderButtonEdit = document.createElement('div');
      orderButtonEdit.classList.add('edit');
      orderButtonEdit.innerHTML = '> Keuze wijzigen';
      orderMenu.append(orderButtonEdit);

      // Add / edit note
      let orderButtonNote = document.createElement('div');
      orderButtonNote.classList.add('notebutton');
      orderButtonNote.innerHTML = '> Notitie';
      orderMenu.append(orderButtonNote);

      // Remove order
      let orderButtonRemove = document.createElement('div');
      orderButtonRemove.classList.add('remove');
      orderButtonRemove.innerHTML = '> Bestelling verwijderen';
      orderMenu.append(orderButtonRemove);

    order.append(orderMenu);

    return order;
  }

  function addOrUpdateOrder(_person, _drink) {

    // Update order
    let existingOrder = document.querySelector(`#orders .order[data-person="${_person}"]`);
    if (existingOrder) {
      // Person already in list, update order
      let drinkName = getDrinkName(_person, _drink);
      existingOrder.querySelector('.what').innerHTML = drinkName;
      refreshOverview();
      if (_drink == '?') { existingOrder.querySelector('.notebutton').click(); }
      return false;
    }

    // Add guest to people
    if (_person == '?') {
      guestCount++;
      _person = 'g'+guestCount;
      wwcData.people[_person] = { name: 'Gast '+guestCount, code: '', ordered: true, note: '' };
    }

    // UI update
    elWelcome.classList.add('hidden');
    elReset.classList.remove('hidden');

    // Catch already used code
    if (wwcData.people[_person].code != '' && !availableCodes.includes(wwcData.people[_person].code)) {
      log(`Code ${wwcData.people[_person].code} used, set to none!`, 'red');
      wwcData.people[_person].code = '';
    }

    // Create element
    elOrders.append(buildOrderElement(_person, _drink));

    // Set ordered status
    wwcData.people[_person].ordered = true;

    // Remove code from available codes
    if (wwcData.people[_person].code != '') {
      availableCodes.splice(availableCodes.indexOf(wwcData.people[_person].code), 1);
    }

    refreshOverview();

    // Open note form when adding "other" drink
    if (_drink == '?') { orderButtonNote.click(); }

  }

  function resetPerson(_id) {
    wwcData.people[_id].ordered = false;
    wwcData.people[_id].drink = '';
    wwcData.people[_id].note = '';
  }

  function resetOrders() {

    // Clear overview + list
    elOrders.innerHTML = '';
    elOverview.innerHTML = '';

    // Reset guest count
    guestCount = 0;

    // Reset codes
    availableCodes = JSON.parse(JSON.stringify(allCodes));

    // Reset notes + ordered status, remove guests
    Object.keys(wwcData.people).forEach(_key => {
      if (_key.substr(0,1) == 'g') {
        delete wwcData.people[_key];
      } else {
        resetPerson(_key);
      }
    });

    // Reset UI elements
    emptyList();

  }

  // Toggle audio
  elAudioToggle.addEventListener('change', _e => {
    if (_e.currentTarget.checked) {
      wwcApp.settings.audio = true;
    } else {
      wwcApp.settings.audio = false;
    }
    Cookies.set('wwc-audio', wwcApp.settings.audio, { expires: 365 });
  });

  // Click handler
  document.addEventListener('click', _e => {

    if (_e.target.closest('#settingsbutton')) {

      elSettings.classList.toggle('active');
      elSettingsBtn.classList.toggle('active');

      if (elSettings.classList.contains('active')) {
        elSortlist.innerHTML = getPeople();
        $(elSortlist).sortable({
          stop: (_e, _ui) => {
            let customOrder = [];
            elSortlist.querySelectorAll('.button').forEach(_btn => {
              customOrder.push(_btn.dataset.id);
            });
            Cookies.set('wwc-custom-order', JSON.stringify(customOrder), { expires: 365 });
          }
        });
      }

    } else if (_e.target.closest('#add')) {

      // Remove any open note forms and codeSelectors
      document.querySelectorAll('form.addnote').forEach(_noteForm => _noteForm.remove());
      document.querySelectorAll('.selectcode').forEach(_selectCode => _selectCode.remove());

      // Add new order
      populateLists();
      elPeople.style.display = 'block';
      elDrinks.style.display = 'none';
      elBack.classList.remove('hidden');
      elSelector.classList.add('active');

    } else if (_e.target.closest('#people .button')) {

      // Select person
      selectedPerson = _e.target.dataset.id;
      elPeople.style.display = 'none';
      let question = document.createElement('p');
      question.id = 'question';
      let questionName = 'de gast';
      if (selectedPerson != '?') { questionName = wwcData.people[selectedPerson].name; }
      question.innerHTML = `Wat wil ${questionName} drinken?`;
      elDrinks.prepend(question);
      elDrinks.style.display = 'block';

    } else if (_e.target.closest('#drinks .button')) {

      // Select drink
      elSelector.classList.remove('active');
      elBack.classList.add('hidden');
      addOrUpdateOrder(selectedPerson, _e.target.dataset.id);

    } else if (_e.target.closest('#back')) {

      // Cancel adding order
      elSelector.classList.remove('active');
      elBack.classList.add('hidden');

    } else if (_e.target.closest('.options')) {

      let order = _e.target.closest('.order');

      // Remove note form if present
      let noteForm = order.querySelector('form.addnote');
      if (noteForm) { noteForm.remove(); }

      // Toggle order options menu
      order.querySelector('.menu').classList.toggle('expanded');
      _e.stopPropagation();

    } else if (_e.target.closest('.code.unknown') || _e.target.closest('.codebutton')) {

      let order = _e.target.closest('.order');

      // Close menu
      order.querySelector('.menu').classList.remove('expanded');

      // Open or close
      let select = order.querySelector('.selectcode');
      if (!select) {

        // Select code menu
        let codemenu = document.createElement('div');
        codemenu.classList.add('selectcode');

        let codetext = document.createElement('p');
        codetext.innerHTML = 'Kies een dienblad code';
        codemenu.append(codetext);

        availableCodes.forEach(_code => {
          let codebutton = document.createElement('div');
          codebutton.classList.add('button');
          codebutton.innerHTML = _code;
          codemenu.append(codebutton);
        });

        order.append(codemenu);

      } else {
        select.remove();
      }

      _e.stopPropagation();

    } else if (_e.target.closest('.selectcode .button')) {

      let order = _e.target.closest('.order');
      let selectedCode = _e.target.closest('.selectcode .button').innerHTML;

      // Set selected code
      order.querySelector('.code').innerHTML = selectedCode;
      order.querySelector('.code').classList.remove('unknown');

      // Remove selectedCode from available codes
      availableCodes.splice(availableCodes.indexOf(selectedCode), 1);

      // Remove selectmenu + menu button
      order.querySelector('.selectcode').remove();
      order.querySelector('.codebutton').remove();

      _e.stopPropagation();

    } else if (_e.target.closest('.edit')) {

      // Close menu
      _e.target.closest('.order').querySelector('.menu').classList.remove('expanded');

      // Change order
      selectedPerson = _e.target.closest('.order').dataset.person;

      populateLists();

      elPeople.style.display = 'none';
      elDrinks.style.display = 'block';

      let question = document.createElement('p');
      question.id = 'question';
      question.innerHTML = `Wat wil ${wwcData.people[selectedPerson].name} drinken?`;
      elDrinks.prepend(question);

      elBack.classList.remove('hidden');
      elSelector.classList.add('active');

      _e.stopPropagation();

    } else if (_e.target.closest('.notebutton')) {

      let order = _e.target.closest('.order');

      // Close menu
      order.querySelector('.menu').classList.remove('expanded');

      // Add / edit note
      let note = order.querySelector('.note');
      if (note) { note.remove(); } // TODO: Probably can be done better
      let noteText = wwcData.people[order.dataset.person].note;
      let noteForm = document.createElement('form');
      noteForm.classList.add('addnote');
      let noteInput = document.createElement('textarea');
      noteInput.innerHTML = noteText;
      noteForm.append(noteInput);
      let noteSubmit = document.createElement('div');
      noteSubmit.classList.add('button', 'submit');
      if (noteText == '') {
        noteSubmit.innerHTML = 'Notitie toevoegen';
      } else {
        noteSubmit.innerHTML = 'Notitie bewerken';
      }
      noteForm.append(noteSubmit);
      order.append(noteForm);

      _e.stopPropagation();

    } else if (_e.target.closest('.addnote .submit')) {

      let order = _e.target.closest('.order');
      let notetext = _e.target.closest('.addnote').querySelector('textarea').value;

      // Save note
      wwcData.people[order.dataset.person].note = notetext;

      // Remove form
      order.querySelector('form').remove();

      // Show note (TODO: update)
      if (notetext != '') {
        let note = document.createElement('div');
        note.classList.add('note');
        note.innerHTML = notetext;
        order.append(note);
      }

      _e.stopPropagation();

    } else if (_e.target.closest('.remove')) {

      // Remove order
      resetPerson(_e.target.closest('.order').dataset.person)
      _e.target.closest('.order').remove();
      if (elOrders.innerHTML == '') { emptyList(); }
      refreshOverview();
      _e.stopPropagation();

    } else if (_e.target.closest('.addnote textarea') || _e.target.closest('.selectcode')) {

      _e.stopPropagation();

    } else if (_e.target.closest('.order')) {

      // Toggle order done status
      if (!_e.target.closest('.order').classList.contains('done')) {
        if (wwcApp.settings.audio == true) {
          pling.pause();
          pling.currentTime = 0;
          pling.play();
        }
        wwcApp.particle.burst(_e);
      }
      _e.target.closest('.order').classList.toggle('done');

    } else if (_e.target.closest('#reset')) {

      // Check if all orders are checked
      let allDone = true;
      document.querySelectorAll('#orders .order').forEach(_order => {
        if (!_order.classList.contains('done')) {
          allDone = false;
        }
      });

      // Erase order list (confirm first if not all checked)
      if (allDone == false) {
        let agree = confirm('Heel de lijst wissen?');
        if (agree) { resetOrders(); }
      } else {
        resetOrders();
      }

    } else {
      return false;
    }

  }, false);

  return {
    init: init,
  }

}

exports.indexController = indexController;