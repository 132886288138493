'use strict';

/* -- Initialize main object -- */
/* -------------------------------------------------------------------------------------- */
global.wwcApp = {
  settings: {
    audio:     true,
    debugMode: true,
    catLog:    false,
  }
};

/* -- Load requirements -- */
/* -------------------------------------------------------------------------------------- */
const helper      = require('./_helper.js');
const controllers = {
  common:   require('./controllers/ctrl_common.js'),
  api:      require('./controllers/ctrl_api.js'),
  index:    require('./controllers/ctrl_index.js'),
  nav:      require('./controllers/ctrl_nav.js'),
  debug:    require('./controllers/ctrl_debug.js'),
  particle: require('./controllers/ctrl_particle.js'),
};

/* -- Main controller -- */
/* -------------------------------------------------------------------------------------- */
let mainController = function() {

  log("\n");
  log('-= App init =-', 'green; padding: 4px 12px; background-color: #eeffed; border: 1px solid green; border-radius: 5px');

  wwcApp.debug     = controllers.debug.debugController();
  wwcApp.helper    = helper;
  wwcApp.common    = controllers.common.commonController();
  wwcApp.api       = controllers.api.apiController();
  wwcApp.index     = controllers.index.indexController();
  wwcApp.nav       = controllers.nav.navController();
  wwcApp.particle  = controllers.particle.particleController();

  if (window.cordova.platformId == 'browser') {
    //
  } else if (window.cordova.platformId == 'android') {
    //
  }

  wwcApp.api.loadData();

}

/* -- Init -- */
if (window.hasOwnProperty('cordova')) {
  document.addEventListener('deviceready', () => wwcApp.main = mainController(), false);
} else {
  window.cordova = { platformId: 'browser' };
  wwcApp.main = mainController();
}