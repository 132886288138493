/* -- Debug controller -- */
/* -------------------------------------------------------------------------------------- */
/*                                                                                        */
/*  Debug and test page related code                                                      */
/*                                                                                        */
/* -------------------------------------------------------------------------------------- */

let debugController = function() {

  let elTest = document.getElementById('test');

  // Click handler
  document.addEventListener('click', function(_e) {
    if (_e.target.closest('#test')) {

      // Test

    } else {
      return false;
    }
  }, false);

}

exports.debugController = debugController;