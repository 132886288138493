/* -- Common controller -- */
/* -------------------------------------------------------------------------------------- */
/*                                                                                        */
/*  Code that is used everywhere                                                          */
/*                                                                                        */
/* -------------------------------------------------------------------------------------- */

let commonController = function() {

  let locked = false;
  let unblockFunction    = false;
  let elBlockInteraction = document.getElementById('blockinteraction');

  // Interaction block
  let blockInteraction = function(_id, _func = false, _lock = true) {
    elBlockInteraction.classList.add('active');
    if (_func != false) {
      // log('\u29BF --> Block with '+_id);
      unblockFunction = {
        id: _id,
        func: _func,
      };
    }
    if (_lock != false) {
      locked = true;
    }
  }

  // Release interaction block
  let unlockInteraction = function(_id) {
    locked = false;
  }

  // Release interaction block
  let releaseInteraction = function(_id) {
    if (unblockFunction != false) {
      if (!_id || unblockFunction.id == _id) {
        elBlockInteraction.classList.remove('active');
        // if (_id) { log('\u29BF Release '+_id); } else { log(`\u29BF Release any (${unblockFunction.id})`); }
        let func = unblockFunction.func;
        unblockFunction = false;
        func();
      }
    } else {
      elBlockInteraction.classList.remove('active');
    }
  }

  // Click handler
  document.addEventListener('click', function(_e) {
    if (_e.target.closest('#blockinteraction')) {
      if (locked == true) {
        log('Sorry, locked...', 'red');
      } else {
        releaseInteraction();
      }
    } else {
      return false;
    }
  }, false);

  return {
    blockInteraction:   blockInteraction,
    unlockInteraction:  unlockInteraction,
    releaseInteraction: releaseInteraction,
  }

};

exports.commonController = commonController;