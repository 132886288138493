/* -- API controller -- */
/* -------------------------------------------------------------------------------------- */
/*                                                                                        */
/*  Used to communicate with the online API                                               */
/*                                                                                        */
/* -------------------------------------------------------------------------------------- */

let apiController = function() {

  let elLoading = document.getElementById('loading'),
      wwcData = {};

  // Local JSON (temporary, remove this code when API is done)
  const loadJSON = (_path, _callback) => {
    const xObj = new XMLHttpRequest();
    xObj.overrideMimeType('application/json');
    xObj.open('GET', _path, true);
    xObj.onreadystatechange = () => {
      if (xObj.readyState === 4 && xObj.status === 200) {
        _callback(xObj.responseText);
      }
    };
    xObj.send(null);
  }

  let loadData = () => {

    // loadJSON('./data/appdata.json', response => {
    loadJSON('https://wwc.mcw.nl/api.php?apikey=eAMyV5CGsbcmKVbPpxQKHUSDQ84ebKe5', _response => {
      wwcData = JSON.parse(_response);
      elLoading.classList.add('done');
      wwcApp.index.init(wwcData);
    });

  }

  return {
    loadData: loadData,
  }

}

exports.apiController = apiController;